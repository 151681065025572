import Pager from '../components/pager'
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Truncate from 'react-truncate-html'
import Seo from '../components/seo'

const BlogArchive = ({ data, pageContext, location }) => {
  const posts = data.allContentfulBlogPost.edges
  const categories = data.allContentfulCategories.edges
  const { categoryName } = pageContext

  return (
    <Layout location={location}>
      <div className="blog">
        <Seo
          title="Blog | Adam Doe"
          description={'nothin'}
          //pathname={pageData.slug}
          article
        />
        <div className="container">
          <div className="row">
            <div className="left col-12 col-lg-8 order-2 order-md-1">
              {`${categoryName}` !== 'undefined' ? (
                <h1>
                  Notes on <span class="capitalize">{`${categoryName}`}</span>
                </h1>
              ) : (
                <h1>Notes</h1>
              )}
              <div className="border"></div>

              <div className="blog__grid">
                {posts.map((post) => {
                  return (
                    <div className="blog-item-preview">
                      <Link
                        to={`/blog/${post.node.category[0].categoryName}/${post.node.slug}`}
                      >
                        {post.node.title}
                      </Link>
                      <Truncate
                        lines={3}
                        dangerouslySetInnerHTML={{
                          __html:
                            post.node.description.childMarkdownRemark.html,
                        }}
                      />
                      {/* Post Date */}
                      {post.node.publishDate}
                      {/* time to read */}
                      <time dateTime={post.rawDate}>
                        {post.publishDate}
                      </time> –{' '}
                      {post.node.body?.childMarkdownRemark?.timeToRead} minute
                      read
                    </div>
                  )
                })}
              </div>

              <Pager pageContext={pageContext} />
            </div>
            {/* <aside className="right col-12 col-lg-4 order-md-2 p-relative">
                <div className="category-box">
                  <h3 className="section-headline">Topics</h3>
                  <ul className="article-list">
                    <li>
                      {categories.map((cat) => (
                        <Link to={`blog/${cat.node.categoryName}`}>
                          {cat.node.categoryName}
                        </Link>
                      ))}
                    </li>
                  </ul>
                </div>
              </aside> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = ({ pageContext }) => graphql`
  query PostIndexQuery($categoryName: [String]) {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: {
        category: { elemMatch: { categoryName: { in: $categoryName } } }
      }
    ) {
      edges {
        node {
          title
          slug
          tags
          description {
            childMarkdownRemark {
              html
            }
          }
          body {
            body
            childMarkdownRemark {
              html
              timeToRead
            }
          }
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          category {
            categoryName
          }
        }
      }
    }

    allContentfulCategories {
      edges {
        node {
          id
          categoryName
        }
      }
    }
  }
`

export default BlogArchive
